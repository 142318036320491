
import React, { Component } from 'react'

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { createClient, updateClient } from "../../helpers/Database";

class ClientFormDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      submit: false,
      firstName: "",
      lastName: "",
      gender: "",
      emailAddress: "",
      phoneNumber: "",
      streetName: "",
      houseNumber: "",
      additionalAddress: "",
      city: "",
      zipCode: "",
      countryName: "Switzerland",
      countryCode: "CH",
      insuranceNumber: "",
    };
  }

  resetState() {
    this.setState({
      submit: false,
      firstName: "",
      lastName: "",
      gender: "",
      emailAddress: "",
      phoneNumber: "",
      streetName: "",
      houseNumber: "",
      additionalAddress: "",
      city: "",
      zipCode: "",
      countryName: "Switzerland",
      countryCode: "CH",
      insuranceNumber: "",
    })
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.client && this.props.clientId && (this.props.clientId !== prevProps.clientId)) {
      this.setState({
        firstName: this.props.client.name.first || "",
        lastName: this.props.client.name.last || "",
        gender: "",
        emailAddress: this.props.client.email || "",
        phoneNumber: this.props.client.phone || "",
        streetName: this.props.client.address.house.street || "",
        houseNumber: this.props.client.address.house.number || "",
        additionalAddress: this.props.client.address.house.additional || "",
        city: this.props.client.address.zip.city || "",
        zipCode: this.props.client.address.zip.code || "",
        countryName: this.props.client.address.country.name || "",
        countryCode: this.props.client.address.country.code || "",
        insuranceNumber: this.props.client.insurance || "",
      })
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  didCreateClient = clientRef => {

  }

  handleSubmit = event => {
    event.preventDefault()

    createClient(
      this.state.firstName,
      this.state.lastName,
      this.state.gender,
      this.state.emailAddress,
      this.state.phoneNumber,
      this.state.streetName,
      this.state.houseNumber,
      this.state.additionalAddress,
      this.state.city,
      this.state.zipCode,
      this.state.countryName,
      this.state.countryCode,
      this.state.insuranceNumber,
      this.didCreateClient
      )

    this.props.onUpdate()
  }

  handleUpdate = event => {
    event.preventDefault()

    updateClient(
      this.props.clientId,
      this.state.firstName,
      this.state.lastName,
      this.state.gender,
      this.state.emailAddress,
      this.state.phoneNumber,
      this.state.streetName,
      this.state.houseNumber,
      this.state.additionalAddress,
      this.state.city,
      this.state.zipCode,
      this.state.countryName,
      this.state.countryCode,
      this.state.insuranceNumber)

    this.props.onUpdate()
  }

  handleCancel = event => {
    this.props.onCancel()
    this.resetState()
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <form onSubmit={this.handleSubmit}>
            <DialogContent>
              <Typography variant="h6" gutterBottom>
                New Client Information
            </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label="First name"
                    defaultValue={this.state.firstName}
                    fullWidth
                    autoComplete="given-name"
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Last name"
                    defaultValue={this.state.lastName}
                    fullWidth
                    autoComplete="family-name"
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="emailAddress"
                    name="emailAddress"
                    label="Email Address"
                    defaultValue={this.state.emailAddress}
                    fullWidth
                    autoComplete="off"
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone Number"
                    defaultValue={this.state.phoneNumber}
                    fullWidth
                    autoComplete="off"
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={9}>
                  <TextField
                    required
                    id="streetName"
                    name="streetName"
                    label="Street"
                    defaultValue={this.state.streetName}
                    fullWidth
                    autoComplete="off"
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    id="houseNumber"
                    name="houseNumber"
                    label="Number"
                    defaultValue={this.state.phoneNumber}
                    type="number"
                    fullWidth
                    autoComplete="off"
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="additionalAddress"
                    name="additionalAddress"
                    label="Additional Address"
                    defaultValue={this.state.additionalAddress}
                    fullWidth
                    autoComplete="off"
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    id="zipCode"
                    name="zipCode"
                    label="Zip Code"
                    defaultValue={this.state.zipCode}
                    fullWidth
                    autoComplete="shipping postal-code"
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="city"
                    name="city"
                    label="City"
                    defaultValue={this.state.city}
                    fullWidth
                    autoComplete="shipping address-level2"
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    id="countryCode"
                    name="countryCode"
                    label="Country"
                    defaultValue={this.state.countryCode}
                    fullWidth
                    autoComplete="off"
                    defaultValue="CH"
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="insuranceNumber"
                    name="insuranceNumber"
                    label="Insurance Number"
                    defaultValue={this.state.insuranceNumber}
                    fullWidth
                    autoComplete="off"
                    onChange={this.handleInputChange}
                  />
                </Grid>
              </Grid>

            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={this.handleCancel}
              >
                Cancel
              </Button>

              {!this.props.clientId && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
              >
                Add
              </Button>
              )}

              {this.props.clientId && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleUpdate}
              >
                Update
              </Button>
              )}
            </DialogActions>
          </form>
        </Dialog>
      </div>)
  }
}

export default ClientFormDialog;