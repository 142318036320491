import React, { Component } from 'react'

import AppointmentRequests from './AppointmentRequests';

class AdminNotifications extends Component {

    constructor() {
        super();

        this.state = {
        };
    }

    handleSelectRequest = (requestId, request) => {
    }

    render() {
        return (
            <div>
                <AppointmentRequests onSelect={this.handleSelectRequest} />
            </div >
        );
    }
}

export default AdminNotifications;