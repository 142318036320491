
import React, { Component } from 'react'

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import ClientPicker from './ClientPicker'
import LocationPicker from './LocationPicker'

import { readClients, createEvent } from "../../helpers/Database";

class AppointmentForm extends Component {

  constructor(props) {
    super(props);

    // Client
    var client = null
    var clientId = null
    if (props.client && props.clientId) {
      client = props.client
      clientId = props.clientId
    }

    // Slot
    var locationId = null
    var location = null
    if (props.slot) {
      locationId = props.slot.locationRef.id
      location = props.slot.location
    }

    this.state = {
      open: false,
      submit: false,
      notes: "",
      date: "",
      startTime: "",
      endTime: "",
      location: location,
      locationId: locationId,
      slot: props.slot,
      slotId: props.slotId,
      client: client,
      clientId: clientId
    };

    console.log("AppointmentForm", this.state)
  }

  updateDateAndTime = (start, end) => {

    if (!start || !end) {
      return
    }

    var date = start.toISOString().split('T')[0]
    var startTime = start.toTimeString().split(' ')[0]
    var endTime = end.toTimeString().split(' ')[0]

    const startTimeComponents = startTime.split(':')
    const startTimeHHMM = `${startTimeComponents[0]}:${startTimeComponents[1]}`

    const endTimeComponents = endTime.split(':')
    const endTimeHHMM = `${endTimeComponents[0]}:${endTimeComponents[1]}`

    this.setState({ date: date, startTime: startTimeHHMM, endTime: endTimeHHMM })
  }

  componentDidMount() {

    if(this.state.slot) {
      this.updateDateAndTime(this.state.slot.start.toDate(), this.state.slot.end.toDate())
    } else if (this.props.end && this.props.start) {
      this.updateDateAndTime(this.props.start, this.props.end)
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.open !== prevProps.open) {
      this.setState({ open: this.props.open })
    }

    if (this.props.start !== prevProps.start) {
      if (this.props.end && this.props.start) {
        this.updateDateAndTime(this.props.start, this.props.end)
      }
    }
  }

  handleClientPickerChange = (clientId, client) => {

    this.setState({
      client: client,
      clientId: clientId,
    })
  }

  handleLocationPickerChange = (locationId, location) => {

    this.setState({
      locationId: locationId,
    })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  didCreateEvent = clientRef => {
    this.props.onCreate()
  }

  handleSubmit = event => {
    event.preventDefault()

    var dateComponent = this.state.date.match(/^(\d{4})-(\d{2})-(\d{2})$/);
    var startTimeComponent = this.state.startTime.match(/^(\d{2}):(\d{2})$/);
    var endTimeComponent = this.state.endTime.match(/^(\d{2}):(\d{2})$/);

    var start = new Date(dateComponent[1], dateComponent[2] - 1, dateComponent[3], startTimeComponent[1], startTimeComponent[2])
    var end = new Date(dateComponent[1], dateComponent[2] - 1, dateComponent[3], endTimeComponent[1], endTimeComponent[2])

    createEvent(start, end, `Appointment with ${this.state.client.name.first} ${this.state.client.name.last}`, "therapy", this.state.slotId, this.state.slot, this.state.locationId, this.state.clientId, this.state.client, this.didCreateEvent)
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
          <form onSubmit={this.handleSubmit}>
            <DialogContent>
              <Typography variant="h6" gutterBottom>
                New Appointment
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ClientPicker clientId={this.state.clientId} client={this.state.client} onChange={this.handleClientPickerChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="date"
                    name="date"
                    label="Date"
                    type="date"
                    defaultValue={this.state.date}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    required
                    id="startTime"
                    name="startTime"
                    label="Start"
                    type="time"
                    fullWidth
                    defaultValue={this.state.startTime}
                    onChange={this.handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    required
                    id="endTime"
                    name="endTime"
                    label="End"
                    type="time"
                    fullWidth
                    defaultValue={this.state.endTime}
                    onChange={this.handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocationPicker
                    locationId={this.state.locationId} location={this.state.location}
                    id="location"
                    name="location"
                    label="Location"
                    onChange={this.handleLocationPickerChange} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="notes"
                    name="notes"
                    label="Notes"
                    fullWidth
                    autoComplete="off"
                    onChange={this.handleInputChange}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={this.props.onCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
              >
                Add Appointment
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>)
  }
}

export default AppointmentForm;