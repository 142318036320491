
import React, { Component } from 'react'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CalendarIcon from '@mui/icons-material/Event';
import PlanningIcon from '@mui/icons-material/DateRange';
import PeopleIcon from '@mui/icons-material/People';

class AdminDashboardMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: 0
        };
    }

    componentDidUpdate() {
        //...
    }

    handleListItemClick = (index) => {
        this.setState({ selectedIndex: index })
        this.props.onSelectionChange(index);
    }

    render() {
        return (
            <List>
                <ListItem button
                    selected={this.state.selectedIndex === 0}
                    onClick={(event) => this.handleListItemClick(0, event)}
                >
                    <ListItemIcon>
                        <CalendarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Calendar" />
                </ListItem>
                <ListItem button
                    selected={this.state.selectedIndex === 1}
                    onClick={(event) => this.handleListItemClick(1, event)}>
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Clients" />
                </ListItem>
                <ListItem button
                    selected={this.state.selectedIndex === 2}
                    onClick={(event) => this.handleListItemClick(2, event)}>
                    <ListItemIcon>
                        <PlanningIcon />
                    </ListItemIcon>
                    <ListItemText primary="Availability" />
                </ListItem>
            </List>
        )
    }
}

export default AdminDashboardMenu;