import React, { Component } from 'react'

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';

import {
    onChangeAppointmentRequests
} from "../../helpers/Database";

class NavigationBarRequestsButton extends Component {

    constructor(props) {
        super();

        this.state = {
            numberOfRequests: 0
        };
    }

    addAppointmentRequest = (doc) => {
        this.setState(prevState => ({
            numberOfRequests: prevState.numberOfRequests + 1
        }))
    }

    deleteAppointmentRequest = (docId) => {
        this.setState(prevState => ({
            numberOfRequests: prevState.numberOfRequests - 1
        }))
    }

    componentDidMount() {

        // Update badge count
        onChangeAppointmentRequests(this.addAppointmentRequest, this.deleteAppointmentRequest)
    }

    render() {
        return (
            <IconButton color="inherit" onClick={this.props.onClick}>
            <Badge badgeContent={this.state.numberOfRequests} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        );
    }
}

export default NavigationBarRequestsButton;