
import React, { Component } from 'react'

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Typography from '@mui/material/Typography';
import ZurichTime from '../../helpers/ZurichTimeFormat';

import { readClient, readEvent, deleteEvent } from "../../helpers/Database";

class AppointmentView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      client: null,
      event: null
    };
  }

  componentDidMount() {
    readEvent(this.props.eventId, this.updateEvent)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.eventId !== prevProps.eventId) {
      
      readEvent(this.props.eventId, this.updateEvent)
    }
  }

  updateEvent = (event) => {
    console.log("AppointmentView.updateEvent", event)
    this.setState({
      event: event
    })

    if(event.attendees[0]) {
      readClient(event.attendees[0].id, this.updateClient)
    }
  }

  updateClient = (clientId, client) => {
    this.setState({
      client: client
    })
  }

  handleDelete = event => {

    // Delete slot and close when deletion is completed with success
    deleteEvent(this.props.eventId, this.state.event, this.props.onClose)
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
          <DialogContent>
            {this.state.event && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    {this.state.event.summary}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    {this.state.event.start.date.toDate().toDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    {ZurichTime.formattedTime(this.state.event.start.date.toDate(),'hh:mm')} – {ZurichTime.formattedTime(this.state.event.end.date.toDate(),'hh:mm')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    TODO LOCATION
                  </Typography>
                </Grid>
              </Grid>
            )}
            {this.state.client && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5" gutterBottom>
                    {this.state.client.name.first} {this.state.client.name.last}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5" gutterBottom>
                    {this.state.client.email}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleDelete} variant="contained" color="secondary" startIcon={<DeleteIcon />}>
              Delete
            </Button>
            <Button color="primary" onClick={this.props.onClose}>
              Close
              </Button>
          </DialogActions>
        </Dialog>
      </div>)
  }
}

export default AppointmentView;