import React from 'react';
import Layout from "../../components/layout"
//import { Router } from "@reach/router"

// import Profile from "../components/auth/Profile"
// import Login from "../components/auth/Login"
import LoginState from "../../components/auth/LoginState"
import AuthRoute from "../../components/auth/AuthRoute"

import AdminDashboard from "../../components/admin/AdminDashboard"

const AdminPage = (props) => (
    <main>
        <AuthRoute path="/admin/dashboard" component={AdminDashboard} />
  </main>
)

export default AdminPage
