import React from "react"
import { getUser } from "../../helpers/auth"

import LoginState from "./LoginState"

const Profile = () => {
  const user = getUser();
  const { displayName, email, emailVerified } = user;
  const accessToken = user.stsTokenManager.accessToken; 

  return (
    <div title="Your Profile">
      <LoginState />
        <div>
          <p className="text-sm text-gray-600 flex items-center mb-4">
            Members only
          </p>
          <p className="text-gray-700 text-base">
            <ul>
                <li> 
                  <div className="text-sm"><b>Name</b>:</div> 
                  <div className="pl-2 ">{`${displayName}`}</div>
                </li>
                <li> 
                  <div className="text-sm"><b>Email</b>:</div> 
                  <div className="pl-2 ">{`${email}`}</div>
                  </li>
                <li> 
                  <div className="text-sm"><b>Email Verified</b>:</div> 
                  <div className="pl-2 ">{`${emailVerified}`}</div>
                  </li>
                <li> 
                  <div className="text-sm"><b>Firebase Access Token</b>:</div> 
                  <div className="pl-2 truncate" >{`${accessToken}`}</div>
                </li>
            </ul>
          </p>
        </div>
    </div>
  )
}

export default Profile
