import React, { Component } from 'react'

import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import styled from "styled-components";

import AppointmentSlotForm from './AppointmentSlotForm';
import AppointmentSlotView from './AppointmentSlotView';

//Actions
import {
  onChangeAppointmentSlots
} from "../../helpers/Database";

class CalendarEvent {
  constructor(title, allDay, start, end, eventId, slotId, slot) {
    this.title = title;
    this.allDay = allDay;
    this.start = start;
    this.end = end;
    this.eventId = eventId;
    this.slotId = slotId;
    this.slot = slot;
  }
  toString() {
    return this.title + ', ' + this.start + ', ' + this.end;
  }
}

var calendarEventConverter = {
  toFirestore: function (city) {
    return {
      summary: ""
    }
  },
  appointmentSlotToCalendarEvent: function (snapshot) {
    const data = snapshot.data();
    return new CalendarEvent(data.location.name, false, data.start.toDate(), data.end.toDate(), null, snapshot.id, data)
  }
}

class AppointmentSlotCalendarView extends Component {

  constructor() {
    super();

    const minTime = new Date();
    minTime.setHours(7, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(21, 0, 0);

    this.state = {
      calendarEvents: [],
      calendarStep: 30,
      calendarTimeSlots: 1,
      selectedSlot: false,
      minTime: minTime,
      maxTime: maxTime,
      database: null,
    };
  }

  addSlot = (doc) => {
    const newSlot = calendarEventConverter.appointmentSlotToCalendarEvent(doc)
    if (newSlot.slot.available == true) {
      this.setState(prevState => ({
        calendarEvents: [...prevState.calendarEvents, newSlot]
      }))
    }
  }

  deleteSlot = (doc) => {
    const deletedSlotId = doc.id
    this.setState(prevState => ({
      calendarEvents: prevState.calendarEvents.filter(element => element.slotId !== deletedSlotId)
    }))
  }

  updateSlot = (doc) => {
    const newSlot = calendarEventConverter.appointmentSlotToCalendarEvent(doc)
    if (newSlot.slot.available == true) {
    this.setState(prevState => ({
      calendarEvents: [...prevState.calendarEvents, newSlot]
    }))
    }
  }

  componentDidMount() {

    // Watch for changes
    onChangeAppointmentSlots(this.addSlot, this.updateSlot, this.deleteSlot)
  }

  handleSelectCalendarSlot = ({ start, end }) => {
    console.log("handleSelectSlot");
    this.setState({
      selectedSlot: true,
      slotStart: start,
      slotEnd: end
    })
  }

  handleDeselectCalendarSlot = (event) => {
    this.setState({
      selectedSlot: false,
      slotStart: null,
      slotEnd: null
    })
  }

  handleSelectCalendarEvent = (calendarEvent) => {
    console.log("handleSelectEvent", calendarEvent.slotId);
    if (calendarEvent.slotId !== null) {
      this.setState({
        selectedEvent: true,
        selectedSlotId: calendarEvent.slotId
      })
    }
  }

  handleDeselectCalendarEvent = (event) => {
    this.setState({
      selectedEvent: false,
      selectedSlotId: null
    })
  }

  render() {
    const CalendarContainer = styled.div`
    .rbc-events-container {
      margin-right: 0;
    }
  `;

    return (
      <div className="Calendar">
        <AppointmentSlotForm open={this.state.selectedSlot} onCreate={this.handleDeselectCalendarSlot} onCancel={this.handleDeselectCalendarSlot} start={this.state.slotStart} end={this.state.slotEnd} />
        <AppointmentSlotView open={this.state.selectedEvent} onClose={this.handleDeselectCalendarEvent} slotId={this.state.selectedSlotId} />
        <Calendar
          selectable='ignoreEvents'
          step={this.state.calendarStep}
          timeslots={this.state.calendarTimeSlots}
          min={this.state.minTime}
          max={this.state.maxTime}
          events={this.state.calendarEvents}
          defaultView={Views.WEEK}
          localizer={momentLocalizer(moment)}
          onSelectEvent={this.handleSelectCalendarEvent}
          onSelectSlot={this.handleSelectCalendarSlot}
          eventPropGetter={
            (calendarEvent, start, end, isSelected) => {
                let eventStyle = {
                    backgroundColor: "lightblue",
                    color: 'black'
                };

                if (calendarEvent.slotId) {
                      eventStyle.backgroundColor = "yellow"
                }
                else if (calendarEvent.slot.available == false) {
                  eventStyle.backgroundColor = "orange"
                }
                
                return {
                    className: "",
                    style: eventStyle
                };
            }
        }
        />
      </div>
    );
  }
}

export default AppointmentSlotCalendarView;