import React, { Component } from 'react'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';

import moment from 'moment';

//Actions
import {
    readClients
} from "../../helpers/Database";

class ClientOption {
    constructor(clientId, client) {
        this.clientId = clientId
        this.client = client
        this.description = `${client.name.first} ${client.name.last}`
    }
}

class ClientPicker extends Component {

    constructor(props) {
        super();

        var options = []
        var defaultOption = {}

        if (props.client && props.clientId) {
            var uniqueOption = new ClientOption(props.clientId, props.client)
            options = [uniqueOption]
            defaultOption = uniqueOption
        }

        this.state = {
            options: options,
            defaultOption: defaultOption
        };        
    }

    componentDidMount() {

        if (this.props.clientId) {
            return
        }

        this.updateClients()
    }

    updateClients = () => {
        readClients().then(querySnapshot => {
            var clientOptions = []
            var defaultClientOption = {}
            querySnapshot.forEach(doc => {
                var option = new ClientOption(doc.id, doc.data())
                defaultClientOption = option
                clientOptions.push(option)
            })
            this.setState({ options: clientOptions, defaultOption: defaultClientOption })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        // ignore
    }

    handlePickOption = (event, option) => {
        if (option) {
            this.props.onChange(option.clientId, option.client)
        }
    }

    render() {
        return (
            <Autocomplete
                id="combo-box-demo"
                options={this.state.options}
                defaultValue={this.state.defaultOption}
                onChange={this.handlePickOption}
                getOptionLabel={(option) => option.description}
                renderInput={(params) => <TextField {...params}
                label="Client"
                />}
            />
        );
    }
}

export default ClientPicker;