import React, { Component } from 'react'

import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';

class NavigationBarAuthButton extends Component {

    constructor(props) {
        super();

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <IconButton color="inherit" onClick={this.props.onClick}>
                <AccountCircle />
            </IconButton>
        );
    }
}

export default NavigationBarAuthButton;