import React, { Component } from 'react'

import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import styled from "styled-components";

import AppointmentForm from './AppointmentForm';
import AppointmentView from './AppointmentView';

//Actions
import {
    readAppointmentSlots,
    readAppointmentRequests,
    readEvents,
    onChangeEvents,
    onChangeAppointmentSlots,
    onChangeAppointmentRequests,
} from "../../helpers/Database";

class CalendarEvent {
    constructor(title, allDay, start, end, eventId, slotId, slot, requestId) {
        this.title = title;
        this.allDay = allDay;
        this.start = start;
        this.end = end;
        this.eventId = eventId;
        this.slotId = slotId;
        this.slot = slot;
        this.requestId = requestId;
    }
    toString() {
        return this.title + ', ' + this.start + ', ' + this.end;
    }
}

var calendarEventConverter = {
    toFirestore: function (city) {
        return {
            summary: ""
        }
    },
    eventToCalendarEvent: function (snapshot) {
        const data = snapshot.data();
        return new CalendarEvent(data.summary, false, data.start.date.toDate(), data.end.date.toDate(), snapshot.id, null, null, null)
    },
    appointmentSlotToCalendarEvent: function (snapshot) {
        const data = snapshot.data();
        return new CalendarEvent(data.location.name, false, data.start.toDate(), data.end.toDate(), null, snapshot.id, data, null)
    },
    appointmentRequestToCalendarEvent: function (snapshot) {
        const data = snapshot.data();
        return new CalendarEvent("", false, data.slot.start.toDate(), data.slot.end.toDate(), null, null, null, snapshot.id)
    }
}

class AdminCalendarView extends Component {

    constructor() {
        super();

        const minTime = new Date();
        minTime.setHours(7, 0, 0);
        const maxTime = new Date();
        maxTime.setHours(21, 0, 0);

        this.state = {
            calendarEvents: [],
            minTime: minTime,
            maxTime: maxTime,
            calendarStep: 30,
            calendarTimeSlots: 1,
            database: null,
        };
    }

    addAppointmentSlot = (doc) => {
      // Check if slot is available and add it to the calendar
        const newAppointmentSlot = calendarEventConverter.appointmentSlotToCalendarEvent(doc)
        if (newAppointmentSlot.slot.available == true) {
          this.setState(prevState => ({
              calendarEvents: [...prevState.calendarEvents, newAppointmentSlot]
          }))
        }
    }

    updateAppointmentSlot = (doc) => {
      // Check if slot is available and add it to the calendar
        const newAppointmentSlot = calendarEventConverter.appointmentSlotToCalendarEvent(doc)
        if (newAppointmentSlot.slot.available == true) {
          this.setState(prevState => ({
              calendarEvents: [...prevState.calendarEvents, newAppointmentSlot]
          }))
        }
    }

    deleteAppointmentSlot = (doc) => {
        const deletedSlotId = doc.id
        this.setState(prevState => ({
            calendarEvents: prevState.calendarEvents.filter(element => element.slotId !== deletedSlotId)
        }))
    }

    addAppointmentRequest = (doc) => {
        const newAppointmentRequest = calendarEventConverter.appointmentRequestToCalendarEvent(doc)
        this.setState(prevState => ({
            calendarEvents: [...prevState.calendarEvents, newAppointmentRequest]
        }))
    }

    deleteAppointmentRequest = (docId) => {
        const deletedRequestId = docId
        this.setState(prevState => ({
            calendarEvents: prevState.calendarEvents.filter(element => element.requestId !== deletedRequestId)
        }))
    }

    addEvent = (doc) => {
        const newEvent = calendarEventConverter.eventToCalendarEvent(doc)
        this.setState(prevState => ({
            calendarEvents: [...prevState.calendarEvents, newEvent]
        }))
    }

    deleteEvent = (doc) => {
        const deletedEventId = doc.id
        this.setState(prevState => ({
            calendarEvents: prevState.calendarEvents.filter(element => element.eventId !== deletedEventId)
        }))
    }

    componentDidMount() {

        // Watch for changes
        onChangeAppointmentSlots(this.addAppointmentSlot, this.updateAppointmentSlot, this.deleteAppointmentSlot)
        onChangeAppointmentRequests(this.addAppointmentRequest, this.deleteAppointmentRequest)
        onChangeEvents(this.addEvent, this.deleteEvent)
    }

    handleSelectCalendarSlot = ({ start, end }) => {
        this.setState({
            selectedCalendarSlot: true,
            slotStart: start,
            slotEnd: end
        })
    }

    handleSelectCalendarEvent = (calendarEvent) => {
        if (calendarEvent.eventId !== null) {
          console.log("set selectedCalendarEvent"+calendarEvent.eventId);
            this.setState({
                selectedCalendarEvent: true,
                selectedEventId: calendarEvent.eventId
            })
        }
        else if (calendarEvent.slotId !== null && calendarEvent.slot !== null) {
            this.setState({
                selectedCalendarSlot: true,
                slotStart: calendarEvent.start,
                slotEnd: calendarEvent.end,
                selectedSlotId: calendarEvent.slotId,
                selectedSlot: calendarEvent.slot
            })
        }
    }

    handleDeselectCalendarEvent = (event) => {
        this.setState({
            selectedCalendarEvent: false,
            selectedEventId: null,
            selectedSlot: null,
            selectedSlotId: null,
        })
    }

    handleDeselectCalendarSlot = (event) => {
        this.setState({
            selectedCalendarSlot: false,
            selectedEventId: null,
            selectedSlot: null,
            selectedSlotId: null,
        })
    }

    render() {
      let allViews = Object.keys(Views).map(k => Views[k])

      const CalendarContainer = styled.div`
      .rbc-events-container {
        margin-right: 0;
      }
    `;

        return (
            <div className="Calendar">
                {this.state.selectedCalendarSlot && (
                    <AppointmentForm open={this.state.selectedCalendarSlot} onCreate={this.handleDeselectCalendarSlot} onCancel={this.handleDeselectCalendarSlot} start={this.state.slotStart} end={this.state.slotEnd} slot={this.state.selectedSlot} slotId={this.state.selectedSlotId}/>
                )}
                <AppointmentView open={this.state.selectedCalendarEvent} onClose={this.handleDeselectCalendarEvent} eventId={this.state.selectedEventId} />
                <Calendar
                    selectable='ignoreEvents'
                    step={this.state.calendarStep}
                    timeslots={this.state.calendarTimeSlots}
                    min={this.state.minTime}
                    max={this.state.maxTime}
                    events={this.state.calendarEvents}
                    views={allViews}
                    defaultView={Views.WEEK}
                    localizer={momentLocalizer(moment)}
                    onSelectEvent={this.handleSelectCalendarEvent}
                    onSelectSlot={this.handleSelectCalendarSlot}
                    eventPropGetter={
                        (calendarEvent, start, end, isSelected) => {
                            let eventStyle = {
                                backgroundColor: "lightblue",
                                color: 'black'
                            };

                            if (calendarEvent.eventId) {
                                eventStyle.backgroundColor = "lightblue"
                            } else if (calendarEvent.requestId) {
                                eventStyle.backgroundColor = "orange"
                            } else if (calendarEvent.slotId) {
                                eventStyle.backgroundColor = "yellow"
                            }

                            return {
                                className: "",
                                style: eventStyle
                            };
                        }
                    }
                />
            </div>
        );
    }
}

export default AdminCalendarView;