
import React, { Component } from 'react'

import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import EnhancedTable from './EnhancedTable';

import { readClients, onChangeClients } from "../../helpers/Database";

class ClientList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      clients: {},
    };
  }

  updateClients = () => {
  
    const newClients = {}

    readClients().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        newClients[doc.id] = doc.data()
      });

      this.setState({
        clients: newClients,
      })
    })
  }

  componentDidMount() {

    // First update 
    this.updateClients()

    // Watch for changes
    onChangeClients(this.updateClients)
  }

  handleClick = (event, clientId, client) => {
    this.props.onSelectClient(clientId, client)
  }

  render() {
    return (
      <div>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell align="right">Last Appointment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(this.state.clients).map(([clientId, client]) => {
              return (
                <TableRow hover key="{clientId}" onClick={(event) => this.handleClick(event, clientId, client)}>
                  <TableCell>{client.name.first}</TableCell>
                  <TableCell>{client.name.last}</TableCell>
                  <TableCell>{client.email}</TableCell>
                  <TableCell>{client.phone}</TableCell>
                  <TableCell align="right">?</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        
      </div>)
  }
}

export default ClientList;