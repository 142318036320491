import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { isLoggedIn } from "../../helpers/auth"

const AuthRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() /*&& location.pathname !== `/admin/login`*/) {
    // If we’re not logged in, redirect to the home page.
    if (typeof window !== `undefined`) {
      navigate(`/admin/login`, { replace: true })
    }
    return null
  }

  return <Component {...rest} />
}

AuthRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default AuthRoute
