
import React, { Component } from 'react'

import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Typography from '@mui/material/Typography';

import ZurichTime from '../../helpers/ZurichTimeFormat'

import { readAppointmentSlot, readLocation, deleteAppointmentSlot } from "../../helpers/Database";

class AppointmentSlotView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      location: null,
      slot: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.slotId !== prevProps.slotId) {
      readAppointmentSlot(this.props.slotId, this.updateSlot)
    }
  }

  updateSlot = (slot) => {
    this.setState({
      slot: slot
    })

    if (slot.location) {
      readLocation(slot.location.id, this.updateLocation)
    }
  }

  updateLocation = (location) => {
    this.setState({
      location: location
    })
  }

  handleDelete = event => {

    // Delete slot and close when deletion is completed with success
    deleteAppointmentSlot(this.props.slotId, this.props.onClose)
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
          <DialogContent>
            {this.state.slot && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    {this.state.slot.start.toDate().toDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    {ZurichTime.formattedTime(this.state.slot.start.toDate(), 'hh:mm')} – {ZurichTime.formattedTime(this.state.slot.end.toDate(), 'hh:mm')}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {this.state.location && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5" gutterBottom>
                    {this.state.location.name}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            
            <Button onClick={this.handleDelete} variant="contained" color="secondary" startIcon={<DeleteIcon />}>
              Delete
            </Button>
            <Button color="primary" onClick={this.props.onClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>)
  }
}

export default AppointmentSlotView;