
import React, { Component } from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import ZurichTime from '../../helpers/ZurichTimeFormat'

import AppointmentForm from './AppointmentForm';

import { onChangeAppointmentRequests, createClient, findClient, cancelAppointmentRequest, confirmAppointmentRequest, readClient } from "../../helpers/Database";

class AppointmentRequests extends Component {

  constructor(props) {
    super(props);

    this.state = {
      requests: {},
    };
  }

  addAppointmentRequest = (doc) => {
    var newRequests = this.state.requests
    newRequests[doc.id] = doc.data()
    this.setState(prevState => ({
      requests: newRequests
    }))
  }

  deleteAppointmentRequest = (docId) => {
    var newRequests = this.state.requests
    delete newRequests[docId]
    this.setState(prevState => ({
      requests: newRequests
    }))
  }

  componentDidMount() {

    // Watch for changes
    onChangeAppointmentRequests(this.addAppointmentRequest, this.deleteAppointmentRequest)
  }

  handleClick = (event, requestId, request) => {
  }

  handleAppointmentFormClose = (event) => {

    this.setState({
      confirmingRequestId: null,
      confirmingRequest: null,
      confirmingClientId: null,
      confirmingClient: null,
      appointmentFormOpen: false
    })
  }

  handleReject = (event, requestId, request) => {
    cancelAppointmentRequest(requestId, request, this.deleteAppointmentRequest)
  }

  handleConfirm = (event, requestId, request) => {

    this.setState({
      confirmingRequestId: requestId,
      confirmingRequest: request
    })

    if (request.contact.emailAddress) {
      findClient(request.contact.emailAddress, this.handleConfirmExistingClient, this.handleConfirmNewClient)
    }

  }

  handleConfirmExistingClient = (clientId, client) => {

    if (!this.state.confirmingRequest) {
      return
    }

    this.setState({
      confirmingClientId: clientId,
      confirmingClient: client,
      appointmentFormOpen: true
    })
  }

  handleConfirmNewClient = (clientId, client) => {

    if (!this.state.confirmingRequest) {
      return
    }

    console.log("New Client", this.state.confirmingRequest.contact)
    if (this.state.confirmingRequest.contact) {
      var contact = this.state.confirmingRequest.contact
      createClient(contact.firstName, contact.lastName, "", contact.emailAddress, "", "", 0, "", "", 0, "", "", "", this.handleDidCreateClient)
    }
  }

  handleDidCreateClient = clientRef => {
    readClient(clientRef.id, this.handleConfirmExistingClient)
  }

  handleAppointmentFormCreate = (event) => {

    // Delete Appointment Request
    if (this.state.confirmingRequest && this.state.confirmingRequestId) {
      confirmAppointmentRequest(this.state.confirmingRequestId, this.state.confirmingRequest, this.deleteAppointmentRequest)
    }

    this.handleAppointmentFormClose()
  }

  render() {
    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Appointment Requests
        </Typography>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(this.state.requests).map(([requestId, request]) => {
              return (
                <TableRow hover key="{requestId}" onClick={(event) => this.handleClick(event, requestId, request)}>
                  <TableCell>{request.contact.firstName}</TableCell>
                  <TableCell>{request.contact.lastName}</TableCell>
                  <TableCell>{request.contact.emailAddress}</TableCell>
                  <TableCell>{request.slot.location.name}</TableCell>
                  <TableCell>{request.slot.start.toDate().toDateString()}</TableCell>
                  <TableCell>{ZurichTime.formattedTime(request.slot.start.toDate(), 'hh:mm')} – {ZurichTime.formattedTime(request.slot.end.toDate(),'hh:mm')}</TableCell>
                  <TableCell><Button variant="contained" color="primary" onClick={(event) => this.handleConfirm(event, requestId, request)}>Confirm</Button></TableCell>
                  <TableCell><Button variant="contained" color="secondary" onClick={(event) => this.handleReject(event, requestId, request)}>Reject</Button></TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {this.state.appointmentFormOpen && (
          <AppointmentForm 
          open={this.state.appointmentFormOpen} 
          onCreate={this.handleAppointmentFormCreate} 
          onCancel={this.handleAppointmentFormClose} 
          start={this.state.confirmingRequest.slot.start} 
          end={this.state.confirmingRequest.slot.end} 
          clientId={this.state.confirmingClientId} 
          client={this.state.confirmingClient} 
          slotId={this.state.confirmingRequest.slotRef.id} 
          slot={this.state.confirmingRequest.slot} />
        )}
        </div>)
  }
}

export default AppointmentRequests;