
import React, { Component } from 'react'

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import LocationPicker from './LocationPicker'
import ZurichTime from '../../helpers/ZurichTimeFormat';
import { createAppointmentSlot } from "../../helpers/Database";

class AppointmentSlotForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      submit: false,
      date: Date(),
      startTime: "00:00",
      slotDuration: "01:00",
      slotInterval: "00:15",
      slotCount: "1",
      therapistId: "9uEzjaCYGyQaTThVuqfc",
      locationId: null,
      location: null
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.open !== prevProps.open) {
      this.setState({ open: this.props.open })
    }

    if (this.props.start && this.props.start !== prevProps.start) {
      var date = this.props.start.toISOString().split('T')[0]
      var startTime = this.props.start.toTimeString().split(' ')[0]
      const startTimeComponents = startTime.split(':')
      this.setState({ date: date, startTime: `${startTimeComponents[0]}:${startTimeComponents[1]}` })
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleLocationPickerChange = (locationId, location) => {

    this.setState({
      locationId: locationId,
      location: location
    })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    console.log("validate"+this.state.location)

    if (!this.state.locationId || !this.state.location || !this.state.therapistId) {
      return // TODO better validation and error warning
    }

    var startDateComponent = this.state.date.match(/^(\d{4})-(\d{2})-(\d{2})$/);
    var startTimeComponent = this.state.startTime.match(/^(\d{2}):(\d{2})$/);

    var start = new Date(startDateComponent[1], startDateComponent[2] - 1, startDateComponent[3], startTimeComponent[1], startTimeComponent[2])

    var slotDurationComponent = this.state.slotDuration.match(/^(\d{2}):(\d{2})$/)
    const durationHours = slotDurationComponent[1]
    const durationMinutes = slotDurationComponent[2]

    var slotIntervalComponent = this.state.slotInterval.match(/^(\d{2}):(\d{2})$/)
    const intervalHours = slotIntervalComponent[1]
    const intervalMinutes = slotIntervalComponent[2]

    const slotCount = this.state.slotCount

    var slotNumber
    var slotStart = start
    var slotEnd = slotEnd

    for (slotNumber = 0; slotNumber < slotCount; slotNumber++) {

      // Current slot end
      slotEnd = ZurichTime.momentZh(slotStart).add(durationHours, 'h').add(durationMinutes, 'm').toDate();

      // Create slot
      createAppointmentSlot(slotStart, slotEnd, this.state.therapistId, this.state.locationId, this.state.location)

      // Next slot
      slotStart = ZurichTime.momentZh(slotEnd).add(intervalHours, 'h').add(intervalMinutes, 'm').toDate();
    }

    this.props.onCreate()
    this.handleClose()
  }

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <form onSubmit={this.handleSubmit}>
            <DialogContent>
              <Typography variant="h6" gutterBottom>
                Create Appointment Slots
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <LocationPicker
                    defaultValue={this.state.locationId}
                    id="locationId"
                    name="locationId"
                    label="Location"
                    onChange={this.handleLocationPickerChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="therapistId"
                    name="therapistId"
                    label="Therapist"
                    defaultValue={this.state.therapistId}
                    fullWidth
                    autoComplete="off"
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="date"
                    name="date"
                    label="Date"
                    type="date"
                    defaultValue={this.state.date}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    required
                    id="startTime"
                    name="startTime"
                    label="Start"
                    type="time"
                    fullWidth
                    defaultValue={this.state.startTime}
                    onChange={this.handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    required
                    id="slotDuration"
                    name="slotDuration"
                    label="Duration"
                    type="time"
                    step="600"
                    fullWidth
                    defaultValue={this.state.slotDuration}
                    onChange={this.handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    required
                    id="slotInterval"
                    name="slotInterval"
                    label="Break"
                    type="time"
                    fullWidth
                    defaultValue={this.state.slotInterval}
                    onChange={this.handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    required
                    id="slotCount"
                    name="slotCount"
                    label="Number"
                    type="number"
                    fullWidth
                    defaultValue={this.state.slotCount}
                    onChange={this.handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={this.props.onCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
              >
                Create Slots
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>)
  }
}

export default AppointmentSlotForm;