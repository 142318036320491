import React, { Component } from 'react'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';

import moment from 'moment';

//Actions
import {
    readLocations
} from "../../helpers/Database";

class LocationOption {
    constructor(locationId, location) {
        this.locationId = locationId
        this.location = location
        this.description = `${location.name}, ${location.address.house.street} ${location.address.house.number}`
    }
}

class LocationPicker extends Component {

    constructor(props) {
        super();

        var options = []
        var defaultOption = {}

        if (props.location && props.locationId) {
            var uniqueOption = new LocationOption(props.locationId, props.location)
            options = [uniqueOption]
            defaultOption = uniqueOption
        }

        this.state = {
            options: options,
            defaultOption: defaultOption
        }; 
    }

    componentDidMount() {

        if (this.props.locationId) {
            return
        }

        this.updateLocations()
    }

    updateLocations = () => {
        readLocations().then(querySnapshot => {
            var locationOptions = []
            querySnapshot.forEach(doc => {
                locationOptions.push(new LocationOption(doc.id, doc.data()))
            })
            this.setState({ options: locationOptions })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        // ignore
    }

    handlePickOption = (event, option) => {
        if (option) {
            this.props.onChange(option.locationId, option.location)
        }
    }

    render() {
        return (
            <Autocomplete
                id="combo-box-demo"
                defaultValue={this.state.defaultOption}
                options={this.state.options}
                onChange={this.handlePickOption}
                getOptionLabel={(option) => option.description}
                renderInput={(params) => <TextField {...params}
                    label={this.props.label}
                />}
            />
        );
    }
}

export default LocationPicker;