import React, { Component } from 'react'

import Button from '@mui/material/Button';

import ClientList from './ClientList';
import ClientForm from './ClientForm';

class AdminClients extends Component {

    constructor() {
        super();

        this.state = {
            editing: false,
            selectedClientId: null,
            selectedClient: {}
        };
    }

    handleSelectClient = (clientId, client) => {

        this.setState({
            editing: true,
            selectedClientId: clientId,
            selectedClient: client
        })
    }

    handleCreateClient = (clientId, client) => {
        
        this.setState({
            editing: true,
            selectedClientId: null,
            selectedClient: null
        })
    }

    handleFinishEditing = (clientId, client) => {
        
        this.setState({
            editing: false
        })
    }

    handleCancelEditing = () => {

        this.setState({
            editing: false,
            selectedClientId: null,
            selectedClient: null
        })
    }

    render() {
        return (
            <div>
                <Button variant="outlined" color="primary" onClick={this.handleCreateClient}>
                    New Client
                 </Button>
                <ClientForm open={this.state.editing} clientId={this.state.selectedClientId} client={this.state.selectedClient} onUpdate={this.handleFinishEditing} onCancel={this.handleCancelEditing}/>
                <ClientList onSelectClient={this.handleSelectClient} />
            </div >
        );
    }
}

export default AdminClients;